@import 'src/Styling/variables.scss';

.root {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .arrowRight {
    width:40%;
    position: relative;

    .line {
      width:100%;
      background:white;
      height:1px;
      float:left;
      position: absolute;
      top: 49%;
    }

    .point {
      width: 0; 
      height: 0; 
      position: absolute;
      top: 39%;
      left: calc(100% - 7px);
      margin: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      align-self: center;
      border-top: 2px solid transparent;
      border-bottom: 2px solid transparent;
      border-left: 7px solid white;
    }
  }

  .sectionTitle {
    margin-left: 30px;
    margin-right: 30px;
    white-space: nowrap;
    font-size: 13px;
    font-family: "din-medium";
  }

  .arrowLeft {
    width:40%;
    position: relative;

    .line {
      width:100%;
      background:white;
      height:1px;
      float:left;
      position: absolute;
      top: 49%;
    }

    .point {
      width: 0; 
      height: 0; 
      position: absolute;
      top: 39%;
      border-top: 2px solid transparent;
      border-bottom: 2px solid transparent;
      border-right: 7px solid white;
    }
  }
}

@media (min-width: $SM) {
  .root {
    .sectionTitle {
      font-size: large;
    }
  }
}

@media (min-width: $MD) {
  .root {
    .sectionTitle {
      font-size: 13px;
    }
  }
}

@media (min-width: $LG) {
  .root {
    .sectionTitle {
      font-size: 16px;
    }
  }
}