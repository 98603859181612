@import 'src/Styling/variables.scss';

.root {
  padding-left: 5%;
  padding-right: 5%;
  margin-top: 80px;
  --title-margin-bottom: 1px;
}


@media (min-width: $SM) {
  .root {
    padding-left: 10%;
    padding-right: 10%;
  }
}

@media (min-aspect-ratio: 4 / 3) and (min-width: $MD) {
  .root {
    margin-bottom: 40px;
  }
}

@media (min-width: $MD) {
  .root {
    margin-top: $commonInternalMargin;
  }
}
