@import "src/Styling/variables";

.root {
    .gridContainer {
      display: grid;
      grid-template-columns: repeat(2, minmax(100px, 1fr));
      grid-column-gap: 5vw;
      margin-bottom: $commonInternalMargin * 2;

      .menu {
        margin-top: $commonInternalMargin * 2;
        display: flex;
        flex-direction: column;

        .main {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          border-bottom: 1px solid;
          
          .titles {
            .title {
              font-size: 16px;
              margin: 0;
            }
          }
        }
  
        .subSection {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          border-bottom: 1px solid;
          margin-top: 8px;
          padding-bottom: 8px;
          height: 100%;
          
          .description {
            font-size: 9px;
            font-family: "HelveticaNeue-Light";
          }
  
          .ingredients {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
  
            .ingredient {
              font-size: 10px;
              border: 1px solid;
              padding-left: 2px;
              padding-right: 2px;
              margin-right: 4px;
              padding-bottom: 0;
              margin-top: 4px;
            }
          }
        }
      }
  }
}

@media (min-width: $SM) {
  .root {
    .gridContainer {
      margin-bottom: $commonMarginIpad * 2;

      .menu {
        margin-top: $commonMarginIpad;

        .main {
          padding-bottom: 8px;

          .titles {
            .title {
              font-size: 28px;
              line-height: 1;
            }
          }

          .price {
            font-size: 28px;
            line-height: 1;
          }
        }
  
        .subSection {
          margin-top: 8px;  
          padding-bottom: 12px;
          
          .description {
            font-size: 14px;
            margin-top: 4px;
          }
  
          .ingredients {
            .ingredient {
              font-size: 16px;
              margin-top: 12px;
            }
          }
        }
      }
    }
  }
}

@media (min-width: $MD) {
  .root {
    .gridContainer {

      grid-template-columns: repeat(3, minmax(100px, 1fr));
      grid-column-gap: 3vw;

      .menu {
        .main {
          padding-bottom: 4px;
          
          .titles {
            .title {
              font-size: 24px;
            }
          }

          .price {
            font-size: 24px;
          }
        }

        .subSection {          
          .description {
            font-size: 13px;
          }
    
          .ingredients {
            .ingredient {
              margin-top: 8px;
              font-size: 13px;
            }
          }
        }
      }
    }
  }
}

@media (min-width: $LG) {
  .root {
    .gridContainer {
      grid-template-columns: repeat(4, minmax(100px, 1fr));
      grid-column-gap: 2vw;
    }
  }
}

@media (min-width: $XLG) {
  .root {
    .gridContainer {
      .menu {
        .main {
          padding-bottom: 8px;
          .titles {
            .title {
              font-size: 32px;
            }
          }

          .price {
            font-size: 32px;
          }
        }

        .subSection {          
          .description {
            font-size: 16px;
          }
    
          .ingredients {
            .ingredient {
              margin-top: 16px;
              margin-bottom: 8px;
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}