$commonMargin: 16px;
$commonMarginIpad: 56px;
$commonInternalMargin: 20px;
$footerHeightMobile: 40px;
$commonAboutUsSectionVerticalMargin: 28px;
$commonAboutUsSectionVerticalMarginMacbook: 36px;
$commonAboutUsSectionVerticalMarginIMac: 44px;

// screen size
$SM: 768px;
$MD: 1024px;
$LG: 1400px;
$XLG: 1920px;