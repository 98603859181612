@import "src/Styling/variables";

.root {
  margin-bottom: 50px;

  .bentoInfo {
    .bentoImageContainer {
      display: flex;
      justify-content: center;
      max-width: 70%;
      margin: 0 auto;
      
      .menuImage { 
        object-fit: contain;
        margin-top: 56px;
        margin-bottom: 28px;
        width: 100%;
      }
    }

    
    .main {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      border-bottom: 1px solid;
      margin-bottom: 16px;

      .title {
        height: 40px;
        font-size: xx-large;
        margin-bottom: var(--title-margin-bottom);
      }

      .price {
        min-width: 80px;
        font-size: xx-large;
      }
    }

    .contents {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: x-large;
      font-family: "DINCond-Medium-Regular";
      

      .plus {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-size: 40px;
        font-size: 1.25em;
        padding: 0 8px;
      }

      .row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        width: 100%;

        .box {
          border: 1px solid;
          text-align: center;
          padding: 4px 16px;
          box-sizing: border-box;
          width: 45%;
        }
      }
    }

    .subSection {
      display: flex;
      flex-direction: row;
      margin-top: 16px;
      width: 100%;

      .verticalLine {
        border-left: 1px solid;
        height: auto;
      }
      

      .subSectionItem {
        padding: 4px;
        margin: 0 auto;
        /* width: 100%; */
        position: relative;
        /* text-align: right; */
        padding: 0 2vw;

        .subSectionItemContent {
          margin: 0 auto;
        }
    
        &:first-child {
          margin-left: 0;
          padding-left: 0;
        }

        &:last-child {
          border: none;
          // this is to remove the space on right side
          margin-right: 0;
          padding-right: 0;
        }

        .itemTitle {
          font-size: 16px;
        }

        .description {
          font-size: 9px;
          margin-bottom: 20px;
          font-family: "HelveticaNeue-Light";
        }

        .ingredients {
          display: flex;
          flex-direction: row;
          margin-top: 16px;
          position: absolute;
          bottom: 0px;

          .ingredient {
            font-size: 10px;
            border: 1px solid;
            padding-left: 2px;
            padding-right: 2px;
            margin-right: 4px;
            padding-bottom: 0;
          }
        }
      }

      .edamame {
        min-width: 90px;
      }

      .soup {
        min-width: 68px;
      }
    }
  }
}

@media (min-width: $SM) {
  .root {
    .bentoInfo {  
      .bentoImageContainer {
        .menuImage {
          width: 70%;
        }
      }
      

      .main {
        .title {
          height: 64px;
          font-size: 64px;
        }

        .price {
          font-size: 64px;
        }
      }

      .contents {
        font-size: 28px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .musubi {
          border: 1px solid;
          padding: 4px;
          text-align:center;
          height: fit-content;
          padding: 4px 24px;
        }

        .side {
          border: 1px solid;
          padding: 4px;
          text-align:center;
          height: fit-content;
          padding: 4px 24px;
        }
      
        .edamame {
          border: 1px solid;
          padding: 4px;
          text-align:center;
          height: fit-content;
          margin: auto;
          padding: 4px 24px;
        }

        .soup {
          border: 1px solid;
          padding: 4px;
          text-align:center;
          height: fit-content;
          margin: auto;
          padding: 4px 24px;
        }
      }

      .subSection {
        margin-top: 32px;   

        .subSectionItem {
          .itemTitle {
            font-size: large;
          }

          .edamame {
            white-space: nowrap;
          }

          .description {
            font-size: 13px;
            margin-bottom: 24px;
          }

          .ingredients {
            margin-top: 18px;
            .ingredient {
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}

@media (min-width: $MD) {
  .root {
    .bentoInfo {
      display: flex;
      flex-direction: row;
      justify-content: space-between;


      .bentoImageContainer {
        max-width: 44%;
        margin-left: 0;
        .menuImage {
          width: 100%;
        }
      }

      .right {
        margin: auto;
        max-width: 50%;
        margin-right: 0;

        .main {
          .title {
            height: 48px;
            font-size: 48px;
          }

          .price {
            font-size: 48px;
          }
        }

        .contents {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          font-size: x-large;
          font-family: "DINCond-Medium-Regular";
          
    
          .plus {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
            font-size: 40px;
            font-size: 1.25em;
            padding: 0 8px;
          }
    
          .row {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 20px;
            width: 100%;
    
            .box {
              border: 1px solid;
              text-align: center;
              padding: 4px 16px;
              box-sizing: border-box;
              width: 45%;
            }
          }
        }

        .subSection {
          margin-top: 0;
          .subSectionItem {
            margin-left: 2%;
            margin-right: 2%;
            padding: 0 1%;

            &:first-child {
              margin-left: 0;
              padding-left: 0;
            }

            &:last-child {
              border: none;
              // this is to remove the space on right side
              margin-right: 0;
              padding-right: 0;
            }

            .itemTitle {
              font-size: large;
            }

            .description {
              font-size: 13px;
            }

            .ingredients {
              .ingredient {
                font-size: 13px;
              }
            }
          }

          .edamame {
            min-width: 148px;
          }
        }
      }
    }
  }
}

@media (min-width: $LG) {
  .root {
    .bentoInfo {
      display: flex;
      flex-direction: row;

      .bentoImageContainer {
        max-width: 40%;
        margin-left: 0;

        .menuImage {
          width: 100%;
        }
      }

      .right {
        margin: auto;
        max-width: 52%;
        margin-right: 0;

        .main {
          .title {
            height: 68px;
            font-size: 56px;
          }

          .price {
            font-size: 56px;
          }
        }

        .contents {
          font-size: x-large;
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          .plus {
            font-size: 1.5em;
          }

          .textContainer {
            display: inline-block;
            text-align: center;
            line-height: 1.2;
            border: 1px solid;
            text-align: center;
            height: fit-content;
            padding: 4px 24px;
          }
          
          .firstRow, .secondRow {
            display: block; // This makes each span behave like a div
          }
        }

        .subSection {
          margin-top: $commonAboutUsSectionVerticalMarginMacbook;
          
          .subSectionItem {
            margin-left: 3%;
            margin-right: 3%;

            &:first-child {
              margin-left: 0;
              padding-left: 0;
            }

            &:last-child {
              border: none;
              // this is to remove the space on right side
              margin-right: 0;
              padding-right: 0;
            }

            .itemTitle {
              font-size: x-large;
              margin-bottom: 4px;
            }

            .description {
              font-size: 13px;
              margin-bottom: 28px;
            }

            .ingredients {
              .ingredient {
                font-size: 13px;
              }
            }
          }

          .edamame {
            min-width: 148px;
          }
        }
      }
    }
  }
}

@media (min-width: $XLG) {
  .root {
    .bentoInfo {

      .bentoImageContainer {
        .menuImage {
          margin-top: 80px;
        }
      }

      .right {
        width: 84%;
        max-width: 56%;

        .main {
          .title {
            height: 92px;
            font-size: 76px;
          }

          .price {
            font-size: 76px;
          }
        }

        .contents {
          font-size: xx-large;

          .textContainer {
            padding: 8px 40px;
          }
          
          .firstRow, .secondRow {
            display: block; // This makes each span behave like a div
          }
        }

        .subSection {
          margin-top: $commonAboutUsSectionVerticalMarginIMac;
          
          .subSectionItem {
            margin-left: 4%;
            margin-right: 4%;

            .itemTitle {
              margin-bottom: 8px;
              font-size: xx-large;
            }

            .description {
              font-size: 16px;
              margin-bottom: 36px;
            }

            .ingredients {
              .ingredient {
                font-size: 16px;
              }
            }
          }
        }
      }
    }
  }
}