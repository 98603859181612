@import 'src/Styling/variables.scss';

.subTitle {
  font-size: 24px;
  font-family: "DINCond-Medium-Regular";
}

@media (min-width: $SM) {
  .subTitle {
    font-size: 44px;
  }
}

@media (min-aspect-ratio: 4 / 3) {
  .subTitle {
    font-size: 16px;
  }
}

@media (min-width: $MD) {
  .subTitle {
    font-size: 32px;
  }
}

@media (min-width: $XLG) {
  .subTitle {
    font-size: 48px;
    margin-bottom: 4px;
    line-height: 0.9;
  }
}