.root {
  position: fixed;
  bottom: 0;
  border-top: 1px solid;
  width: 100%;
  background-color: #222021;
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 6px;
  padding-bottom: 8px;
  font-size: 13px;
}