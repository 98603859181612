@import "src/Styling/variables";

.root {
  .locations {
    max-height: 70vh;
    overflow: scroll;
    margin-top: $commonInternalMargin * 2;
    margin-bottom: $commonInternalMargin * 2;
  }
}

@media (min-width: $SM) {
  .root {
    .locations {
      margin-top: $commonMarginIpad;
      margin-bottom: $commonMarginIpad * 2;
    }
  }
}