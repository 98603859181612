.root {
  border-top: 1px solid;
  padding-left: 5%;
  padding-right: 5%;
  font-size: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0;
  padding-top: 24px;

  .icons {
    display: flex;
    flex-direction: row;
    width: 16%;
    justify-content: space-between;

    .imageContainer {
      display: flex;
      height: 24px;
      width: 24px;

      .image {
        margin-top: auto;
        max-width: 100%;
        max-height: 100%;
        margin: auto;
      }
    }
  }
}