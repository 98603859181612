@import "src/Styling/variables";

.root {
  .gridContainer {
    display: grid;
    grid-template-columns: repeat(2, minmax(100px, 1fr));
    grid-column-gap: 5vw;
    margin-bottom: $commonInternalMargin * 2;

    .menu {
      margin-top: $commonInternalMargin;
      display: flex;
      flex-direction: column;

      .menuImage {
        width: 100%;
        object-fit: contain;
      }

      .main {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border-bottom: 1px solid;
  
        

        .titles {
          height: 32px;
          margin-bottom: 4px;

          .title {
            font-size: 16px;
            margin: 0;
            line-height: 1.05;
            max-width: 100%;
            display: inline-block;
          }
  
          .subTitle {
            font-size: 13px;
            margin: 0;
            line-height: 1; // this is required to avoid any spacing issue
            font-family: "DINCond-Medium-Regular";
          }
        }
  
        .price {
          min-width: 35px;
          text-align: right;
        }
      }
  
      .subSection {
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-top: 4px;
        padding-bottom: 8px;
        border-bottom: 1px solid;
        
        .description {
          font-size: 9px;
          font-family: "HelveticaNeue-Light";
        }
  
        .ingredients {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
  
          .ingredient {
            font-size: 10px;
            border: 1px solid;
            padding-left: 2px;
            padding-right: 2px;
            margin-right: 4px;
            padding-bottom: 0;
            margin-top: 4px;
          }
        }
      }
    }
  }
}

@media (min-width: $SM) {
  .root {
    .gridContainer {
      margin-bottom: $commonMarginIpad * 2;

      .menu {
        margin-top: $commonInternalMargin;

        .main {
          .titles {
            height: 56px;
            
            .title {
              font-size: 28px;
              line-height: 1;
            }
    
            .subTitle {
              font-size: x-large;
              line-height: 1.2;
            }
          }
    
          .price {
            font-size: xx-large;
            min-width: 80px;
            line-height: 1;
          }
        }
        
        .subSection {     
          margin-top: 8px;     
          padding-bottom: 12px;

          .description {
            font-size: 14px;
            
          }
    
          .ingredients {
            .ingredient {
              font-size: 16px;
              margin-top: 12px;
            }
          }

        }
      }
    }
  }
}

@media (min-width: $MD) {
  .root {
    .gridContainer {
      grid-template-columns: repeat(3, minmax(100px, 1fr));
      grid-column-gap: 3vw;

      .menu {
        .main {
          .titles {
            height: 48px;
            
            .title {
              font-size: 24px;
            }
    
            .subTitle {
              font-size: 20px;
              white-space: nowrap;
            }
          }

          .price {
            font-size: 24px;
            min-width: 60px;
          }
        }

        .subSection {          
          .description {
            font-size: 13px;
          }
    
          .ingredients {
            .ingredient {
              margin-top: 8px;
              font-size: 13px;
            }
          }
        }
      }
    }
  }
}

@media (min-width: $LG) {
  .root {
    .gridContainer {
      grid-template-columns: repeat(4, minmax(100px, 1fr));
      grid-column-gap: 2vw;
    }
  }
}

@media (min-width: $XLG) {
  .root {
    .gridContainer {
      .menu {
        .main {
          .titles {
            height: 64px;
            
            .title {
              font-size: 32px;
            }
    
            .subTitle {
              font-size: 24px;
              margin-top: 2px;
            }
          }

          .price {
            font-size: 32px;
            min-width: 72px;
          }
        }

        .subSection {          
          .description {
            font-size: 16px;
          }
    
          .ingredients {
            .ingredient {
              margin-top: 16px;
              margin-bottom: 8px;
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}