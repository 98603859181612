@import "src/Styling/variables";


.root {
  overflow-x: hidden;
  
  .bar {
    background-color: #222021;

    .logo {
      max-width: 80%;
      max-height: 72%;
      position: absolute;
      left: 54%;
      transform: translate(-50%);
    }
  }
  
  .drawer {
    background-color: #222021;
    width: 240px;

    .drawerHeader {
      display: flex;
      flex-direction: row;
      min-height: 56px;
      background-color: #222021;
      position: relative;
      padding-left: 4px;

      .clearButton {
        color: white;
        padding: 12px;
      }

      .logo {
        max-width: 60%;
        max-height: 72%;
        position: absolute;
        left: 50%;
        top: 32%;
        transform: translate(-50%);
      }
    }

    .drawerBody {
      padding-left: 5%;
      padding-right: 5%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      margin-top: 5%;
      margin-bottom: 5%;
      background-color: #222021;

      .items {
        .item {
          font-size: 48px;
          margin-bottom: 8px;

          &:first-child {
            margin-bottom: 4px;
          }
        }

        .menuItem {
          font-size: xx-large;
          font-family: "DINCond-Medium-Regular";
          margin-top: 8px;

          &:last-child {
            margin-bottom: 8px;
          }  
        }

        .menuRow {
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          .expandIcon {
            color: white;
          }
        }
      }

      .container {
        display: flex;
        flex-direction: column;
        margin-top: 5%;

        .imageContainer {
          display: flex;
          height: 24px;
          width: 24px;
          margin-top: 28px;
          margin-bottom: 10px;
  
          .image {
            margin-top: auto;
            max-width: 100%;
            max-height: 100%;
            margin: auto;
          }
        }
      }
    }
  }
}

@media (min-width: $MD) {
  .root {
    margin-top: 24px;
    margin-left: 10%;
    margin-right: 10%;

    .fixedNavigation {
      .wideToolBar {
        display: flex;
        flex-direction: row;
        height: 100px;
        border-bottom: 1px solid;
        margin-bottom: 8px;
  
        .wideLogo {
          height: 80%;
          margin: auto;
          margin-left: 0;
        }
  
        .right {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          width: 72%;
  
          .topRow {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;
            margin: auto;
  
            .title {
              display: flex;
              flex-direction: row;
              align-items: center;
              font-size: x-large;
            }
  
            .icons {
              display: flex;
              flex-direction: row;
              width: 16%;
              justify-content: space-between;
      
              .imageContainer {
                display: flex;
                height: 24px;
                width: 24px;
                margin-top: 20px;
                margin-bottom: 10px;
        
                .image {
                  margin-top: auto;
                  max-width: 100%;
                  max-height: 100%;
                  margin: auto;
                }
              }
            }
          }
  
          .bottomRow {
            border-top: 1px solid;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            height: 50%;
            font-family: "din-medium";
            font-size: 12px;
  
            .description {
              display: flex;
              flex-direction: row;
              align-items: center;
            }
  
            .verticalLine {
              border-left: 1px solid;
              height: auto;
            }
  
            .sections {
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: space-between;
              width: 40%;

              .sectionItem {
                cursor: pointer;
                margin-left: -12%;
              }
            }
          }
        }
      }

      .menus {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        margin-top: $commonInternalMargin;
        font-family: "din-medium";
        font-size: 12px;

        .menuItem {
          margin-left: 4%;
          cursor: pointer;
        }
      }
    }
  }
}

@media (min-width: $LG) {
  .root {
    .fixedNavigation {
      .wideToolBar {
        height: 120px;
  
        .right {
          .topRow {
            .title {
              font-size: xx-large;
            }
          }
  
          .bottomRow {

            .description {
              font-size: 16px;
            }

            .sections {
              .sectionItem {
                font-size: 16px;
              }
            }
          }
        }
      }

      .menus {
        .menuItem {
          font-size: 16px;
        }
      }
    }
  }
}