@import "src/Styling/variables";

.root {
  .item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  
    .date {
      width: 40px;
      margin-bottom: 30px;
      border-bottom: 1px solid;
      padding-bottom: 4px;
  
      .dayOfWeek {
        font-size: 13px;
        font-family: "DINCond-Medium-Regular";
      }
    }
  
    .title {
      width: 40%;
      margin-bottom: 30px;
      border-bottom: 1px solid;
      padding-bottom: 4px;
      font-size: large;
    }
  
    .details {
      width: 42%;
      margin-bottom: 30px;
      border-bottom: 1px solid;
      padding-bottom: 4px;
  
      .time {
        font-size: 13px;
        font-family: "DINCond-Medium-Regular";
        margin-left: 2px;
      }
      
      .location {
        font-size: 10px;
        display: flex;
        flex-direction: row;
        font-family: "DINCond-Medium-Regular";
  
        .locationIcon {
          padding-top: 1px;
        }
      }
    }
  }
}

@media (min-width: $SM) {
  .root {
    .item {
      .date {
        width: 56px;
  
        .dayOfWeek {
          font-size: 20px;
        }
  
        .monthAndDate {
          font-size: x-large;
        }
      }
  
      .title {
        font-size: x-large;
        width: 40%;
      }
  
      .details {
        width: 44%;
        .time {
          font-size: large;
        }
  
        .location {
          font-size: 16px;
        }
      }
    }
  }
}

@media (min-width: $MD) {
  .root {
    .item {
      .date {
        width: 64px;
  
        .dayOfWeek {
          font-size: 22px;
        }
  
        .monthAndDate {
          font-size: 28px;
        }
      }

      .title {
        font-size: xx-large;
      }
  
      .details {
        .time {
          font-size: 22px;
        }
  
        .location {
          font-size: large;
        }
      }
    }
  }
}

@media (min-width: $LG) {
  .root {
    .item {
        margin-bottom: $commonAboutUsSectionVerticalMargin;
        display: flex;
        flex-direction: row;
    
        .dayOfWeek {
          font-size: 28px;
          font-family: "DINCond-Medium-Regular";
          border-bottom: 1px solid;
          padding-bottom: 6px;
        }
  
        .monthAndDate {
          font-size: 28px;
          border-bottom: 1px solid;
          padding-bottom: 6px;
        }
    
        .title {
          margin-bottom: 0;
          font-size: xx-large;
          width: 38%;
        }

        .time {
          border-bottom: 1px solid;
          padding-bottom: 4px;
          width: 160px;
          font-size: 24px;
          text-align: center;
          font-family: "DINCond-Medium-Regular";
        }

        .location {
          border-bottom: 1px solid;
          padding-bottom: 4px;
          width: 36%;
          font-size: 24px;
          display: flex;
          flex-direction: row;
          font-family: "DINCond-Medium-Regular";

          .locationIcon {
            padding-top: 2px;
          }
        }
    }
  }
}

@media (min-width: $XLG) {
  .root {
    .item {
      .dayOfWeek {
        font-size: 32px;
      }

      .monthAndDate {
        font-size: 32px;
      }
  
      .title {
        font-size: 38px;
      }
    }
  }
}