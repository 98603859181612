@import "src/Styling/variables";

.root {
  margin-bottom: 64px;

  .riceBallExplanation {
    margin-top: $commonMargin;
    border-bottom: 1px solid white;
    padding-bottom: 13px;

    .titles {
      font-size: 36px;
    }
    
    .main {
      display: flex;
      flex-direction: row;
      margin-top: 22px;

      .description {
        font-family: "din-medium";
        line-height: 1.6;
        font-size: 13px;
        margin: 0;
        padding-right: 2%;
        width: 50%;
      }

      .musubiImage {
        width: 50%;
        object-fit: contain;
      }
    }
  }

  .musubiDescription {

    .title {
      font-size: 36px;
      border-bottom: 1px solid white;
      padding-top: 10px;
      padding-bottom: 10px;
    }

    .description {
      font-family: "din-medium";
      line-height: 1.6;
      font-size: 13px;
    }
  }

  .connector {
    display: flex;
    flex-direction: row;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    margin-top: 10px;

    .left {
      width: calc(100% - 100px);
      padding-top: 20px;

      .title {
        font-size: 28px;
      }

      .description {
        margin-right: 10%;
        line-height: 1.6;
        font-family: "din-medium";
        font-size: 13px;
      }
    }

    .right {
      display: flex;
      flex-direction: column;
      width: 100px;

      .wrapper {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        border-left: 1px solid white;
        border-bottom: 1px solid white;
        height: 50%;

        &:last-child {
          border-bottom: none;
        }

        .imageContainer {
          display: flex;
          height: 80px;
          width: 40px;
          margin-left: 15px;
          margin-right: 15px;
          margin-top: 10px;
          margin-bottom: 10px;
  
          .musubiIcon {
            margin-top: auto;
            max-width: 100%;
            max-height: 100%;
            margin: auto;
          }

          .flagIcons {
            margin-top: auto;
            max-width: 80%;
            max-height: 80%;
            margin: auto;
          }
        }
      }
    }
  }

  .tokachiDescription {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid white;
    align-items: center;

    .left {
      height: 100%;
      width: 50%;
      background-position: center;
      background-repeat: no-repeat;
      padding-top: $commonInternalMargin;
      padding-bottom: $commonInternalMargin;
      padding-right: $commonInternalMargin - 12px;
      height: 180px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      background-origin: content-box;
      background-size: contain;
      border-right: 1px solid white;

      .title {
        font-size: 20px;
        width: 60%;

        .line1 {
          margin: 0;
        }

        .line2 {
          margin: 0;
        }
      }
      
      .imageContainer {
        height: 30%;
        position: relative;
        padding-right: 0;

        .kanjiIcon {
          margin-top: auto;
          max-width: 100%;
          max-height: 100%;
          margin: auto;
          position: absolute;
          right: 5%;
        }
      }
    }

    .right {
      border-left: 1px solid white;
      height: 100%;
      width: calc(50% - 15px);
      padding-left: $commonInternalMargin;
      padding-top: $commonInternalMargin;
      padding-bottom: $commonInternalMargin;
      border-left: 1px solid;
      margin-left: -1px;

      .title {
        font-size: 20px;
        padding-bottom: $commonInternalMargin - 12px;
      }

      .description {
        line-height: 1.6;
        font-family: "din-medium";
        font-size: 13px;
        margin: 0;
      }
    }
  }

  .laCocinaDescription {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid white;

    .left {
      width: 25%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-right: $commonInternalMargin;

      .imageContainer {
        display: flex;
        justify-content: center; 
        align-items: center;     
        max-width: 84%;           // Set a maximum width to ensure the container doesn't stretch too wide
        max-height: 84%;          // Set a maximum height to ensure the container doesn't stretch too tall
        border-radius: 50%;       // make the container circular
        overflow: hidden;         // hide anything that exceeds the container's dimensions
        align-self: center;

        .laCocinaIcon {
          object-fit: cover;       // ensure the image covers its content box without stretching
          width: 100%;             // fills the width of its container
          height: 100%;            // fills the height of its container
        }
      }
    }

    .right {
      border-left: 1px solid white;
      width: calc(75% - 25px);
      padding-left: $commonInternalMargin;

      .description {
        line-height: 1.6;
        font-family: "din-medium";
        font-size: 13px;
      }
    }
  }

  .truckDescription {
    margin-top: 67px;
    margin-bottom: 67px;

    .truckImage {
      width: 100vw;
      margin-left: -5vw;
    }
  }

  .missionDescription {
    border-top: 1px solid white;
    padding-top: $commonMargin - 8px;
    padding-bottom: 22px;

    .title {
      font-size: 36px;
      padding-bottom: $commonMargin - 12px;
    }

    .description1 {
      line-height: 1.6;
      font-family: "din-medium";
      font-size: 13px;
      margin: 0;
    }

    .description2 {
      font-family: "din-medium";
      font-size: 13px;
      padding-top: $commonMargin;
      margin: 0;
    }
  }

  .founderIntroduction {
    display: flex;
    flex-direction: row;
    border-top: 1px solid;
    border-bottom: 1px solid;
    height: 120px;
    justify-content: space-between;
    font-family: "din-medium";
    font-size: 13px;

    .description {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .title {
        margin: 4px;
      }

      .name {
        margin: 4px;
      }
    }

    .imageContainer {
      border-left: 1px solid;
      display: flex;
      // width nneds to be 120px to match the height
      // otherwise the image will be stretched in mobile
      width: 120px;
      height: 120px;

      .image {
        width: 100%;
      }
    }
  }
}

@media (min-width: $SM) {
  .root {
    .riceBallExplanation {
      margin-top: $commonMarginIpad;
      border-bottom: 1px solid white;
  
      .titles {
        font-size: 68px;
      }
      
      .main {
        display: flex;
        flex-direction: row;
        margin-top: 16px;
  
        .description {
          font-family: "din-medium";
          line-height: 1.6;
          font-size: large;
          margin: 0;
          padding-right: 2%;
        }
  
        .musubiImage {
          min-width: 56%;
        }
      }
    }

    .musubiDescription {

      .title {
        font-size: 68px;
        padding-top: calc($commonAboutUsSectionVerticalMargin - 4px);
        padding-bottom: calc($commonAboutUsSectionVerticalMargin - 4px);
      }

      .description {
        font-size: large;
        padding-top: $commonAboutUsSectionVerticalMargin;
        padding-bottom: $commonAboutUsSectionVerticalMargin;
        margin-top: 0;
        margin-bottom: 0;
      }
    }

    .connector {
      margin-top: 0;
      
      .left {
        width: calc(100% - 132px);
        padding-top: $commonAboutUsSectionVerticalMargin;
        padding-bottom: $commonAboutUsSectionVerticalMargin;
        .title {
          font-size: 36px;
          margin-bottom: 0;
          padding-bottom:  calc($commonAboutUsSectionVerticalMargin - 10px);
        }
  
        .description {
          font-size: large;
          margin-top: 0;
          margin-bottom: 0;
        }
      }

      .right {
        width: 132px;
      }
    }

    .tokachiDescription {
      .left {
        padding-top: $commonAboutUsSectionVerticalMargin;
        padding-bottom: $commonAboutUsSectionVerticalMargin;
        height: 280px;
        width: 72%;
        .title {
          font-size: 36px;
          padding-top: 0;
        }
      }

      .right {
        height: 280px;
        padding-top: $commonAboutUsSectionVerticalMargin;
        padding-bottom: $commonAboutUsSectionVerticalMargin;
        padding-left: $commonAboutUsSectionVerticalMargin;
        width: calc(50% - 12px);

        .title {
          font-size: 36px;
          padding-bottom:  calc($commonAboutUsSectionVerticalMargin - 10px);
        }

        .description {
          font-size: large;
        }
      }
    }

    .laCocinaDescription {
      .left {
        height: 200px;
        width: 200px;
        
        .imageContainer {
          width: 60%;
        }
      }

      .right {
        padding-left: $commonAboutUsSectionVerticalMargin;
        .description {
          font-size: large;
        }
      }
    }

    .truckDescription {
      margin-top: 67px;
      margin-bottom: 67px;
      position: relative;
      left: -12.5%;
  
      .truckImage {
        width: 100vw;
        margin-left: 0;
      }
    }

    .missionDescription {
      padding-top: calc($commonAboutUsSectionVerticalMargin - 4px);
      padding-bottom: $commonAboutUsSectionVerticalMargin;

      .title {
        font-size: 68px;
        
      }

      .description1 {
        font-size: large;
        padding-top: calc($commonAboutUsSectionVerticalMargin - 16px);
      } 
      
      
      .description2 {
        font-size: large;
        padding-top: $commonAboutUsSectionVerticalMargin;
      }
    }

    .founderIntroduction {
      height: 200px;

      .description {
        font-size: large;
      }

      .imageContainer {
        height: 200px;
        width: 200px;
      }
    }
  }
}

@media (min-width: $MD) {
  .root {
    .riceBallExplanation {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      margin-top: 60px;
      padding-bottom: 0;
      width: 100%; // stretch the container to the full width

      .left {
        margin-bottom: $commonAboutUsSectionVerticalMargin;
        margin-left: 0;
        width: 540px;

        .titles {
          font-size: 60px;
          line-height: 1.0;
        }
        .description {
          font-size: 14px;
          width: 88%;
          margin-top: $commonAboutUsSectionVerticalMargin;
          line-height: 1.6;
          font-family: "din-medium";
          width: 360px;
        }
      }

      .right {
        display: flex;
        justify-content: center;
        min-width: 380px;
        flex: 1; // take up the remaining space

        .musubiImage {
          width: 100%;
          height: 100%;
          max-width: 380px;
        }
      }
    }

    .descriptionsContainer {
      .title {
          font-size: 60px;
          margin-top: calc($commonAboutUsSectionVerticalMargin - 8px);
          margin-bottom: calc($commonAboutUsSectionVerticalMargin - 8px);
      }

      .explanations {
        display: flex;
        flex-direction: row;
        border-top: 1px solid;
        border-bottom: 1px solid;
        
        .left {
          border-right: 1px solid white;
          width: 26%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          padding-top: $commonAboutUsSectionVerticalMargin;
          padding-right: calc($commonAboutUsSectionVerticalMargin + 12px);
          padding-bottom: $commonAboutUsSectionVerticalMargin;

          .description {
            font-family: "din-medium";
            font-size: 14px;
            padding-right: 0;
            line-height: 1.6;
            margin-bottom: 0;
            margin-top: 0;
          }

          .connectorWrapper {
            .title {
              font-size: 26px;
              padding-right: 0;
            }
          }
        }

        .center {
          width: 48%;

          .imagesContainer {
            display: flex;
            flex-direction: row;
            border-bottom: 1px solid;
            height: 280px;
            

            .icons {
              display: flex;
              flex-direction: column;
              
              .wrapper {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                border-bottom: 1px solid white;
                height: 50%;
        
                &:last-child {
                  border-bottom: none;
                }
        
                .imageContainer {
                  display: flex;
                  height: 100px;
                  width: 100px;
                  margin-left: 15px;
                  margin-right: 15px;
                  margin-top: 10px;
                  margin-bottom: 10px;
          
                  .musubiIcon {
                    margin-top: auto;
                    max-width: 48%;
                    max-height: 48%;
                    margin: auto;
                  }
        
                  .flagIcons {
                    margin-top: auto;
                    width: 40%;
                    margin: auto;
                  }
                }
              }
            }

            .tokachiImage {
              border-left: 1px solid white;
              width: 100%;
              background-position: center;
              background-repeat: no-repeat;
              padding-top: 20px;
              padding-bottom: 20px;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              background-origin: content-box;
              background-size: contain;
              background-position: 76%;
        
              .title {
                font-size: 26px;
                margin-top: 0;
                margin-left: $commonAboutUsSectionVerticalMargin;
        
                .line1 {
                  margin: 0;
                }
        
                .line2 {
                  margin: 0;
                }
              }
              
              .imageContainer {
                height: 20%;
                position: relative;
        
                .kanjiIcon {
                  margin-top: auto;
                  max-width: 100%;
                  max-height: 100%;
                  margin: auto;
                  position: absolute;
                  right: 8%;
                }
              }
            }
          }

          .tokachiDescription {
            border-bottom: none;
            display: flex;
            flex-direction: column;
            padding-left: $commonAboutUsSectionVerticalMargin;
            padding-right: $commonAboutUsSectionVerticalMargin;

            .title {
              font-size: 26px;
              margin-bottom: 0;
              margin-left: 0;
              display: flex;
              align-self: flex-start;
              margin-top: calc($commonAboutUsSectionVerticalMargin - 4px);
            }

            .description {
              font-family: "din-medium";
              font-size: 14px;
              line-height: 1.6;
            }
          }
        }

        .right {
          border-left: 1px solid;
          width: 26%;

          .laCocinaDescription {
            display: flex;
            flex-direction: row;
            border-bottom: none;
            display: flex;
            flex-direction: column;
            justify-content: center;
      
            .imageContainer {
              display: flex;
              align-items: center;
              border-bottom: 1px solid;
              height: 140px;
              
      
              .laCocinaIcon {
                margin-top: auto;
                max-width: 56%;
                max-height: 56%;
                margin: auto;
              }
            }

            .descriptionWrapper {
              padding-left: calc($commonAboutUsSectionVerticalMargin + 4px);
              padding-top: $commonAboutUsSectionVerticalMargin;
              margin-top: 0;
              font-family: "din-medium";
              line-height: 1.6;

              .description {
                margin-top: 0;
                font-size: 14px;
              }
            }
          }
        }
      }
    }

    .missionDescription {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding-top: 0;
      padding-bottom: 0;
      font-family: "din-medium";
      border-bottom: 1px solid white;
      height: 400px;

      .left {
        padding-right: calc($commonAboutUsSectionVerticalMargin + 4px);

        .title {
          font-size: 64px;
          font-family: "DINCond-BoldRegular";
          margin-top: calc($commonAboutUsSectionVerticalMargin - 8px);
        }

        .description {
          font-size: 14px;
          line-height: 1.6;
        }

        .introduction {
          margin-top: $commonAboutUsSectionVerticalMargin;

          .description {
            margin-top: 0;
            margin-bottom: 0;
          }
        }
      }

      .imageContainer {
        height: 100%;
        border-left: 1px solid;

        .image {
          // fix for image exceeding width of the parent div
          height: 100%;
          width: auto;
          // max-width: 100%;
          object-fit: cover;
        }
      }
    }
  }
}

@media (min-width: $LG) {
  .root {

    .riceBallExplanation {
      .left {
        width: 600px;

        .titles {
          font-size: 78px;
        }

        .description {
          font-size: 16px;
          width: 400px;
        }
      }

      .right {
        .musubiImage {
          max-width: 480px;
        }
      }
    }

    .descriptionsContainer {
      .title {
          font-size: 78px;
      }

      .explanations {
        .left {
          width: 23%;
          padding-right: calc($commonAboutUsSectionVerticalMarginMacbook + 12px);
          padding-top: $commonAboutUsSectionVerticalMarginMacbook;
          padding-bottom: $commonAboutUsSectionVerticalMarginMacbook;

          .description {
            font-size: 16px;
          }

          .connectorWrapper {
            .title {
              font-size: 36px;
            }
          }
        }

        .center {
          width: 54%;

          .imagesContainer {
            height: 320px;

            .icons { 
              .wrapper {
                width: 160px;
        
                .imageContainer {
          
                  .musubiIcon {
                    max-width: 64%;
                    max-height: 64%;
                  }
        
                  .flagIcons {
                    width: 48%;
                  }
                }
              }
            }

            .tokachiImage {
              background-position: 50%;
        
              .title {
                font-size: 36px;
                margin-top: 12px;
              }
              
              .imageContainer {
                height: 24%;
                margin-bottom: 12px;
              }
            }
          }

          .tokachiDescription {
            padding-left: $commonAboutUsSectionVerticalMarginMacbook;
            padding-right: $commonAboutUsSectionVerticalMarginMacbook;

            .title {
              font-size: 36px;
              margin-top: $commonAboutUsSectionVerticalMarginMacbook;
            }

            .description {
              font-size: 16px;
            }
          }
        }

        .right {
          width: 23%;

          .laCocinaDescription {
            .imageContainer {
              height: 160px;

              .laCocinaIcon {
                max-width: 48%;
                max-height: 48%;
              }
            }

            .descriptionWrapper {
              padding-left: $commonAboutUsSectionVerticalMarginMacbook;
              padding-top: $commonAboutUsSectionVerticalMarginMacbook;
            }
          }
        }
      }
    }

    .missionDescription {  
      .left {
        border-right: 0;

        margin-right: 60px;
        width: 64%;

        .title {
          font-size: 68px;
        }

        .description {
          font-size: 16px;
        }
      }
    }
  }
}

@media (min-width: $XLG) {
  .root {

    .riceBallExplanation {
      .left {
        width: 800px;

        .titles {
          font-size: 88px;
        }

        .description {
          font-size: large;
          width: 440px;
        }
      }
    }

    .truckDescription {
      left: unset;
      .truckImage {
        width: 100%;
      }
    }

    .descriptionsContainer {
      .title {
          font-size: 84px;
      }

      .explanations {
        .left {
          padding-right: $commonAboutUsSectionVerticalMarginIMac;
          padding-top: $commonAboutUsSectionVerticalMarginIMac;
          padding-bottom: $commonAboutUsSectionVerticalMarginIMac;

          .description {
            font-size: 20px;
          }

          .connectorWrapper {
            .title {
              font-size: 36px;
            }
          }
        }

        .center {
          .imagesContainer {
            height: 400px;

            .icons { 
              .wrapper {
                width: 200px;
              }
            }

            .tokachiImage {
              .title {
                margin-top: 20px;
                margin-left: $commonAboutUsSectionVerticalMarginIMac;
              }
            }
          }

          .tokachiDescription {
            padding-left: $commonAboutUsSectionVerticalMarginIMac;
            padding-right: $commonAboutUsSectionVerticalMarginIMac;

            .title {
              font-size: 36px;
              margin-top: $commonAboutUsSectionVerticalMarginIMac;
            }

            .description {
              font-size: 20px;
            }
          }
        }

        .right {
          .laCocinaDescription {
            .imageContainer {
              height: 200px;

              .laCocinaIcon {
                max-width: 40%;
                max-height: 40%;
              }
            }

            .descriptionWrapper {
              .description {
                font-size: 20px;
              }
            }
          }
        }
      }
    }

    .missionDescription {
      justify-content: space-between;
      height: 480px;

      .left {
        padding-right: calc($commonAboutUsSectionVerticalMarginIMac + 16px);

        .title {
          margin-top: calc($commonAboutUsSectionVerticalMarginIMac - 8px);
        }

        .description {
          font-size: large;
        }

        .introduction {
          margin-top: $commonAboutUsSectionVerticalMarginIMac;
        }
      }

      .imageContainer {

        .image {
          // fix for image exceeding width of the parent div
          max-width: 100%;
        }
      }
    }
  }
}