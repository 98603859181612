body {
  margin: 0;
  font-family: "DINCond-BoldRegular";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #222021;
  color: white;
  -webkit-text-size-adjust: 100%;
}

@media (min-width: 1920px) {
  #root {
    max-width: 1900px;
    margin: 0 auto;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'DINCond-BoldRegular';
  src: local('DINCond-BoldRegular'), url(./asset/font/DINCond-BoldRegular.otf) format('truetype');
}

@font-face {
  font-family: 'din-medium';
  src: local('din-medium'), url(./asset/font/din-medium.ttf) format('truetype');
}

@font-face {
  font-family: 'DINCond-Medium-Regular';
  src: local('DINCond-Medium-Regular'), url(./asset/font/DINCond-Medium-Regular.otf) format('truetype');
}

@font-face {
  font-family: 'HelveticaNeue-Light';
  src: local('HelveticaNeue-Light'), url(./asset/font/HelveticaNeue-Light.ttf) format('truetype');
}